import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setChainId } from "./actions";
import { AppState } from "../index";

export function useSetChainId(): (chainId: any) => void {
  const dispatch = useDispatch();
  return useCallback(
    (chainId: any) => {
      dispatch(setChainId({ chainId }));
    },
    [dispatch]
  );
}

export function useChainId(): AppState["application"]["chainId"] {
  return useSelector((state: AppState) => state.application.chainId);
}
export function useBalance(): AppState["application"]["balance"] {
  return useSelector((state: AppState) => state.application.balance);
}
