
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import web3NoAccount from "./web3";
import {
  getNftContractAddress,
  getNftMarketplaceContractAddress,
} from "./addressHelpers";
import erc20Abi from "../config/abi/erc20.json";
import nftmaketplaceAbi from "../config/abi/nftmarketplace.json";
import nftAbi from '../config/abi/nft.json';

export const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi as unknown as AbiItem, address);
};

export const getERC20Contract = (address: string, web3?: Web3) => {
  return getContract(erc20Abi, address, web3);
};

export const getnftContract = (web3?: Web3) => {
  return getContract(nftAbi, getNftContractAddress(), web3);
};

export const getnftmarketplaceContract = (web3?: Web3) => {
  return getContract(
    nftmaketplaceAbi,
    getNftMarketplaceContractAddress(),
    web3
  );
};

