// @ts-nocheck

import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import useAuth from "../../hooks/useAuth";
import metamaskIconfrom from '../../images/MetaMask_Fox.svg.png';
import walletIcon from '../../images/walletconnect.png';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    minWidth: '300px',
    backgroundColor: '#242435',
    color: '#fff'
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ConnectWallet({ open, onClose }: any) {
  const { login } = useAuth();
  const handleClose = () => {
    onClose()
  };
  const connectWallet = (type) => {
    login(type);
  }
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Connect Wallet
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
            <Button 
              onClick={()=>connectWallet('injected')}
              fullWidth sx={{ border: '1px solid #ffffff14', marginBottom: '15px'}} startIcon={<img src={metamaskIconfrom} alt="Metamask" width="30px" />}>
              <span
                style={{padding: '8px 6px'}}
                className="header-text"
              >
                MetaMask
              </span>
            </Button>
            <Button 
              onClick={() => connectWallet('walletconnect')}
              fullWidth sx={{ border: '1px solid #ffffff14' }}  startIcon={<img src={walletIcon} alt="Metamask" width="30px" />}>
              <span
                style={{ padding: '8px 6px' }}
                className="header-text"
              >
                Wallet Connect
              </span>
            </Button>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
