// @ts-nocheck
import React from 'react'
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Logo from "../../images/denlogo.png";
import WhitePaper from '../../images/DentistCoinWhitePaper.pdf';
import './index.css'


export default function Footer() {
  return (
    <div>
      <div className='footer-container'>
        <Container maxWidth="xl" sx={{ marginTop: '50px', padding: '100px 0' }}>
          <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <img
                src={Logo}
                alt="Indospring Agrochemical"
                style={{ maxHeight: "35px", objectFit: "cover" }}
              />
              <p className='rn-footer-describe'>

                Dentistcoin is building the ultimate dental ecosystem. Dentistcoin was founded by dentists and innovative tech professionals that have been working in the space for 15 years plus.

              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h6 className='widget-title'>Quick Links</h6>
              <ul className='footer-list-one'>
                <li className="single-list"><a href="/">Home</a></li>
                <li className="single-list"><a href="/index.html">About Us</a></li>
                <li className="single-list"><a href="/blogs">Blog</a></li>
                <li className="single-list"><a href={WhitePaper}>WhitePaper</a></li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h6 className='widget-title'>Useful Links</h6>
              <ul className='footer-list-one'>
                <li className="single-list"><a href="/browse">Explore</a></li>
                <li className="single-list"><a href="/faq">FAQ</a></li>
                <li className="single-list"><a href="/privacypolicy">Privacy Policy</a></li>
                <li className="single-list"><a href="/termsandcondition">Terms & Conditions</a></li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h6 className='widget-title'>Dental Information</h6>
              <ul className='footer-list-one'>
                <li className="single-list"><a href="/nft-dentistcoin-info-about-nfts">Dentistcoin NFT Info</a></li>
                <li className="single-list"><a href="/nft-and-dentistcoin">Crypto Dentist Coin NFTs</a></li>
                <li className="single-list"><a href="/what-is-nft-and-the-future-of-nft">Dentist NFT Coin Tokens</a></li>
                <li className="single-list"><a href="/what-makes-nft-a-great-investment">Cryptocurrency Dentistcoin NFT</a></li>
                <li className="single-list"><a href="/what-challenges-did-nft-face-at-the-beginning">Dentist NFTs Coins Tokens</a></li>
              </ul>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <h6 className='widget-title'>Our Contact Information</h6>
              <a href="mailto:service@dentistcoin.com" style={{ color: '#ffff', textDecoration: 'none' }} >service@dentistcoin.com</a>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className='copy-right-one'>
        <Container maxWidth="xl">
          <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <div className='copyright-left'>
                <span style={{fontSize: '16px'}}>©2022 Dentistcoin, All rights reserved.</span>
                <ul className="privacy">
                  <li><a href="/termsandcondition">Terms</a></li>
                  <li><a href="/privacypolicy">Privacy Policy</a></li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Stack spacing={3} direction="row" alignItems="center" justifyContent="flex-end">
                <IconButton
                  onClick={() => window.open('https://www.facebook.com/DentistCoin ', '_blank')}
                  sx={{ background: '#242435', width: '40px', height: '40px', borderRadius: '100%' }}>
                  <FacebookIcon sx={{ color: '#acacac' }} />
                </IconButton>

                <IconButton
                  onClick={() => window.open(' https://www.instagram.com/dentistcoinnft/', '_blank')}
                  sx={{ background: '#242435', width: '40px', height: '40px', borderRadius: '100%' }}>
                  <TwitterIcon sx={{ color: '#acacac' }} />
                </IconButton>
                <IconButton
                  onClick={() => window.open(' https://twitter.com/dentist_coin  ', '_blank')}
                  sx={{ background: '#242435', width: '40px', height: '40px', borderRadius: '100%' }}>
                  <InstagramIcon sx={{ color: '#acacac' }} />
                </IconButton>
                {false &&
                  <IconButton sx={{ background: '#242435', width: '40px', height: '40px', borderRadius: '100%' }}>
                    <LinkedInIcon sx={{ color: '#acacac' }} />
                  </IconButton>
                }
                {false &&
                <IconButton sx={{ background: '#242435', width: '40px', height: '40px', borderRadius: '100%' }}>
                  <MailOutlineIcon sx={{ color: '#acacac' }} />
                </IconButton>
                }
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}
