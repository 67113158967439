// @ts-nocheck

import React, { useEffect, lazy } from 'react';
import { Router, Route, Switch } from "react-router-dom";
// import { ResetCSS } from "cryption-uikit";
import Web3 from "web3";
import { useSetChainId } from './state/application/hooks'
import useEagerConnect from "./hooks/useEagerConnect";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import PageLoader from "./components/PageLoader";
import Header from './components/Header/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import GoodInvestmet from './views/Articles/GoodInvestmet'
// import Menu from "./components/Menu";
import './App.css';
import history from "./routerHistory";

function App() {
  const Home = lazy(() => import("./views/Home"));
  const NFTDetails = lazy(() => import("./views/NFTDetails"));
  const BlogDetails = lazy(() => import("./views/BlogDetails"));
  const Blogs = lazy(() => import("./views/Blogs"));
  const Browse = lazy(() => import("./views/Browse"));
  const PrivacyPolicy = lazy(() => import("./views/PrivacyPolicy"));
  const TermsAndCondition = lazy(() => import("./views/TermsAndCondition"));
  const FaqPage = lazy(() => import("./views/FaqPage"));
  const Challenges = lazy(() => import("./views/Articles/Challenges"));
  const Benifits = lazy(() => import("./views/Benifits"));
  const Future = lazy(() => import("./views/Articles/Future"));
  const Benefits = lazy(() => import("./views/Articles/Benefits"));
  const Community = lazy(() => import("./views/Articles/Community"));
  const setChainId = useSetChainId();
  useEffect(() => {
    console.warn = () => null;
    if (window && window.ethereum) {
      window.ethereum.on("chainChanged", () => {
        // Handle the new chain.
        // Correctly handling chain changes can be complicated.
        // We recommend reloading the page unless you have good reason not to.
        window.location.reload()
        // eslint-disable-next-line react-hooks/rules-of-hooks
        // window.location.reload();
      });
    }
    const getChainID = async () => {
      const web3 = new Web3(window && window.ethereum ? window.ethereum : process.env.REACT_APP_NETWORK_URL)
      const chianId = await web3.eth.net.getId();
      setChainId(chianId)
      if (localStorage) {
        localStorage.setItem('chainId', chianId.toString());
      }
    }
    getChainID()
  }, [setChainId]);
  useEagerConnect();
  return (
    <Router history={history}>
      <Header />
      <ScrollToTop />
      {/* <GlobalStyle /> */}
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/nft/:id" exact>
            <NFTDetails />
          </Route>
          <Route path="/blog/:id" exact>
            <BlogDetails />
          </Route>
          <Route path="/blogs" exact>
            <Blogs />
          </Route>
          <Route path="/browse" exact>
            <Browse />
          </Route>
          <Route path="/privacypolicy" exact>
            <PrivacyPolicy />
          </Route>
          <Route path="/termsandcondition" exact>
            <TermsAndCondition />
          </Route>
          <Route path="/faq" exact>
            <FaqPage />
          </Route>
          <Route path="/crypto-dentist-coin-nft-benefits-nfts-community" exact>
            <Benifits />
          </Route>
          <Route path="/what-makes-nft-a-great-investment" exact>
            <GoodInvestmet />
          </Route>
          <Route path="/what-challenges-did-nft-face-at-the-beginning" exact>
            <Challenges />
          </Route>
          <Route path="/what-is-nft-and-the-future-of-nft" exact>
            <Future />
          </Route>
          <Route path="/nft-dentistcoin-info-about-nfts" exact>
            <Benefits />
          </Route>
          <Route path="/nft-and-dentistcoin" exact>
            <Community />
          </Route>
        </Switch>
      </SuspenseWithChunkError>
      <Footer />
    </Router>
  );
}

export default App;
