/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Container from "@mui/material/Container";
import imgsrc from '../../images/What makes NFT a great investment.jpg';
import './index.css';

export default function GoodInvestmet() {
  return (
    <div>
      <Container maxWidth="xl" sx={{ paddingTop: '100px', paddingBottom: '50px' }}>
        <div className="fapw__inner">
          <h1 className="faf__title">What makes NFT a great investment?</h1>
          <div className="fasw__img">
            <img src={imgsrc} alt="What makes NFT a great investment?" width="100%" />
          </div>
          <h2>Is NFT art a good investment?</h2>
          <p>The value of NFTs has been rising exponentially over the last few years. Some of the most expensive NFTs are now worth millions of dollars. However, there are also some who say that this is a bubble and that it will eventually burst.</p>
          <p>
            NFTs are a great investment if you can afford them. They're not for people who don't have much money to spare. But if you can afford them, they might give you a nice return on your investment in the future when they become more
            mainstream and accessible to more people.
          </p>
          <p>However, there are some factors that can affect the price of an NFT in the future, such as:</p>
          <ul>
            <li>How many people want to buy it</li>
            <li>How many people want to sell it</li>
            <li>The amount of supply and demand</li>
            <li>The quality of the artwork</li>
            <li>If more artists start creating NFTs</li>
          </ul>
          <h2>Why is NFT a good investment?</h2>
          <p>
            There are many reasons why NFT is a good investment. One of the main reasons is that it is a new asset className. This means that investors can diversify their portfolio with something new and exciting. Another reason is that NFTs are
            scarce and digital, meaning they cannot be copied or counterfeited easily. They are also easy to store and trade, which makes them an attractive alternative for investors who don’t want to deal with paper or metal assets.
          </p>
          <p>Investing in NFTs can be risky because there has been little data on the market, but so far it has been very lucrative</p>

          <p>
            The practice also offers a full range of dental treatments for children from infancy to adolescence. They have pediatric specialists who work with children and their families to ensure that they get the best possible care and treatment.
          </p>
          <h2>What is DentistCoin?</h2>
          <p>
            DentistCoin is a cryptocurrency that will be used to make dental payments. DentistCoin is the first cryptocurrency for dentists. It will allow them to accept payments in the form of cryptocurrency for their services. DentistCoin is
            created to solve the problem of making payments for dental services in countries where there are no credit cards and banks, and where people have no access to banking services.
          </p>
          <h2>Is it profitable to buy NFT?</h2>
          <p>It is safe to say that the NFT market is growing. So, if you are considering purchasing NFTs, it's important to know what you're getting into.</p>
          <p>NFTs are a new form of cryptocurrency. But they can also be used as a store of value like Bitcoin or Ethereum or Dentistcoin.</p>
          <p>In order to buy NFTs, you need to first purchase Dentistcoin or Ethereum or Bitcoin and then exchange it for the desired token on an exchange such as Binance or Kucoin.</p>

        </div>
      </Container>
    </div>
  )
}
