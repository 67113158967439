export default {
  nft: {
    "4": "0x4443a77f0F8B736A9CfbA66817758Ca276cb46c4",
    "1": "0x966d9F9D72Eee038B60c5047A6769b2CEC906410",
  },
  nftmarketplace: {
    "4": "0xed97974dF1547f093177670BA3596b2484f70E49",
    "1": "0x1a22d99d1853b8804eA5c95C87dFdeF8A41f6C88",
  },
  mulltiCall: {
    4: "0x42Ad527de7d4e9d9d011aC45B31D8551f8Fe9821",
    1: "0x5ba1e12693dc8f9c48aad8770482f4739beed696",
  },
  dentistcoin: {
    4: "0x3d0924e2fa774a0Ee73fAA2731fC4961a5C93636",
    1: "0x01D28e618a1B14f12e1A3d7d9F5Ee47c0Fb255CB",
  },
};
