// @ts-nocheck

import addresses from "../config/constants/contracts";

interface Address {
  4?: string;
}
export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID;
  return address[chainId || 1];
};

export const getNftContractAddress = () => {
  return getAddress(addresses.nft);
};

export const getNftMarketplaceContractAddress = () => {
  return getAddress(addresses.nftmarketplace);
};

export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall);
};
export const getDentistcoinAddress = () => {
  return getAddress(addresses.dentistcoin);
};