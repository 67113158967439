// import { ConnectorNames } from "cryption-uikit";
import Web3 from "web3";
import getConnectors from "./web3Connectors";

const connectors = getConnectors();

export const connectorsByName = {
  injected: connectors.injected,
  walletconnect: connectors.walletConnectConnector,
};
// @ts-ignore
export const getLibrary = (provider): Web3 => {
  return provider;
};
