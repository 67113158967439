/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Container from "@mui/material/Container";
import AppBar from "@mui/material//AppBar";
import Button from "@mui/material//Button";
import Stack from "@mui/material//Stack";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getERC20Contract } from "../../utils/contractHelpers";
import { getDentistcoinAddress } from "../../utils/addressHelpers";
import useActiveWeb3React from "../../hooks/index";
import "./index.css";
import Logo from "../../images/denlogo.png";
import { useBalance } from "../../state/application/hooks";
import ConnectWallet from "../ConnectWallet";
import useWeb3 from "../../hooks/useWeb3";
import { setBalance } from "../../state/application/actions";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    background: "#13131d",
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "#acacac",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Header(props: any) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const web3 = useWeb3();
  const [open, setOpen] = React.useState(false);
  const { account } = useActiveWeb3React();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const scrollToDiv = (divId: string) => {
    props.history.push("/home");
    const getCourseElemt = document.getElementById(divId);
    if (getCourseElemt) {
      window.scrollTo({
        top: getCourseElemt.offsetTop,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const getTokenBalance = async () => {
      const dentidtcoinContract = getERC20Contract(getDentistcoinAddress());
      if (account) {
        const getBalance = await dentidtcoinContract.methods
          .balanceOf(account)
          .call();
        const balanceInEther = web3.utils.fromWei(getBalance, "ether");
        dispatch(setBalance({ balance: balanceInEther }));
      }
    };
    getTokenBalance();
  }, [account, dispatch, web3.utils]);
  const addDentistCoint = async () => {
    try {
      const tokenAddress = getDentistcoinAddress();
      const tokenSymbol = "DEN";
      const tokenDecimals = 18;
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
  const balance = useBalance();
  return (
    <AppBar className="header">
      <ConnectWallet open={open} onClose={handleModalClose} />
      <div className="hide-on-big-screen">
        <Toolbar className="herder-justify" sx={{ padding: '0px' }}>
          <Stack
            spacing={2}
            sx={{
              paddingRight: "16px",
              position: "relative",
              marginRight: "8px",
            }}
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="logo-thumbnail"
          >
            <IconButton
              edge="end"
              aria-controls="simple-menu"
              onClick={handleClick}
              style={{ color: "#ffff" }}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>

            <Link to="/">
              <img
                src={Logo}
                alt="Indospring Agrochemical"
                style={{ maxHeight: "35px", objectFit: "cover" }}
              />
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center">
            <div style={{ marginRight: "20px" }}>
              <Button
                sx={{
                  background: "#212e48",
                  color: "#ffffff",
                  padding: "10px 11px",
                  fontSize: "12px",
                  borderRadius: "6px",
                }}
                onClick={handleClickOpen}
              >
                {account
                  ? `${account.slice(0, 6)}...${account.slice(
                    account.length - 6
                  )}`
                  : "Connect Wallet"}
              </Button>
            </div>
          </Stack>
          <StyledMenu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <Link
                to="/"
                className="header-text-mobile"
              // onClick={() => scrollToDiv("home")}
              >
                Home
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <Link
                to="/browse"
                className="header-text-mobile"
              // onClick={() => scrollToDiv("aboutUs")}
              >
                Browse NFT
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                scrollToDiv("properties");
              }}
            >
              <Link
                className="header-text-mobile"
                to="/crypto-dentist-coin-nft-benefits-nfts-community"
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "/crypto-dentist-coin-nft-benefits-nfts-community",
                    "_self"
                  );
                }}
              >
                Benefits
              </Link>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
              }}
            >
              <Link
                className="header-text-mobile"
                to="/faq"
              // onClick={() => scrollToDiv("aboutUs")}
              >
                Faq
              </Link>
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </div>
      <div className="hide-on-phone" style={{ width: "100%" }}>
        <Container maxWidth="xl">
          <Toolbar className="herder-justify">
            <Stack
              sx={{
                paddingRight: "32px",
                position: "relative",
                marginRight: "17px",
              }}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="logo-thumbnail"
            >
              <Link to="/">
                <img
                  src={Logo}
                  alt="Indospring Agrochemical"
                  style={{ maxHeight: "35px", objectFit: "cover" }}
                />
              </Link>
            </Stack>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
              sx={{ width: '100%' }}
            >
              <div className="header-content">
                <Link
                  to="/"
                  className="header-text"
                // onClick={() => scrollToDiv("home")}
                >
                  Home
                </Link>
                <Link
                  className="header-text"
                  to="/browse"
                // onClick={() => scrollToDiv("aboutUs")}
                >
                  Browse NFT
                </Link>
                <Link
                  to="/crypto-dentist-coin-nft-benefits-nfts-community "
                  className="header-text"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(
                      "/crypto-dentist-coin-nft-benefits-nfts-community",
                      "_self"
                    );
                  }}
                >
                  Benefits
                </Link>
                <Link
                  to="/faq"
                  className="header-text"
                // onClick={() => scrollToDiv("contactUS")}
                >
                  Faq
                </Link>
              </div>
              <Stack direction="row" alignItems="center" spacing={2}>
                {account && (
                  <Button
                    sx={{
                      background: "#00a3ff",
                      color: "#ffffff",
                      padding: "10px 11px",
                      fontSize: "12px",
                      borderRadius: "6px",
                    }}
                    onClick={addDentistCoint}
                  >
                    {balance} DentistCoin
                  </Button>
                )}
                <Button
                  sx={{
                    background: "#212e48",
                    color: "#ffffff",
                    padding: "10px 11px",
                    fontSize: "12px",
                    borderRadius: "6px",
                  }}
                  onClick={handleClickOpen}
                >
                  {account
                    ? `${account.slice(0, 6)}...${account.slice(
                      account.length - 6
                    )}`
                    : "Connect Wallet"}
                </Button>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </div>
    </AppBar>
  );
}
