/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { setChainId, setBalance } from "./actions";

export interface ApplicationState {
  chainId?: number | string | null;
  balance?: number | string | null;
}

const initialState: ApplicationState = {
  chainId: 1,
  balance: 0,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setChainId, (state, { payload: { chainId } }) => {
      state.chainId = chainId;
    });
     builder.addCase(setBalance, (state, { payload: { balance } }) => {
       state.balance = balance;
     });
  },
});

export default applicationSlice.reducer;